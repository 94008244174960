import React from "react"
import "./styles.css"

interface Section {
  sectionIndex: number
  isFilled: boolean
}

interface MarkerProps {
  section: Section
  active: boolean
  jumpTo?: (x: number) => any
}

function Marker({ active, section, jumpTo }: MarkerProps) {
  return (
    <span
      className={`${section.isFilled ? "marker filled" : "marker"}`}
      onClick={() => jumpTo(section.sectionIndex)}
    >
      {active && <div className="active"></div>}
      {section.isFilled ? (
        <svg
          width="12"
          height="8"
          viewBox="0 0 12 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.91669 4.58334L4.25002 6.91668L10.0834 1.08334"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        false
      )}
    </span>
  )
}

export default Marker
