import React, { useEffect } from "react"
import history from "../history"
import { Question } from "../typings"
import LearnMore from "./LearnMore"
import { v4 as uuidv4 } from "uuid"
import { PrismicRichText } from "@prismicio/react"
import { useTranslation } from "react-i18next"
import { useLocation, useSearchParams } from "react-router-dom"

const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

const Star = () => (
  <svg
    width="46px"
    height="44"
    viewBox="0 0 46 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.1105 0.731353C22.4825 0.00731409 23.5175 0.00731301 23.8895 0.731352L30.2927 13.1951C30.4379 13.4777 30.7088 13.6745 31.0225 13.7253L44.8549 15.9636C45.6585 16.0937 45.9783 17.078 45.4047 17.6555L35.5296 27.5969C35.3057 27.8223 35.2022 28.1408 35.2509 28.4548L37.3966 42.3019C37.5212 43.1063 36.6839 43.7147 35.9574 43.3476L23.451 37.0279C23.1674 36.8846 22.8326 36.8846 22.549 37.0279L10.0426 43.3476C9.31612 43.7147 8.47878 43.1063 8.60343 42.3019L10.7491 28.4548C10.7978 28.1408 10.6943 27.8223 10.4704 27.5969L0.595335 17.6555C0.0216804 17.078 0.341517 16.0937 1.14507 15.9636L14.9775 13.7253C15.2912 13.6745 15.5621 13.4777 15.7073 13.1951L22.1105 0.731353Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="23"
        y1="-1"
        x2="23"
        y2="49"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D8CA9A" />
        <stop offset="0.546875" stopColor="#EFDFA8" />
        <stop offset="1" stopColor="#DE9C5F" />
      </linearGradient>
    </defs>
  </svg>
)
const calculateSortedScores = (questions: Question[], values: {}) => {
  let scores = {
    "Unikitet/Signifikans": 0,
    "Kärlek/Samhörighet": 0,
    Trygghet: 0,
    "Spänning/Variation": 0,
    "Växa/Utveckling": 0,
    "Ge/Bidra": 0,
  }
  Object.keys(values).forEach((value) => {
    const questionNumber = parseInt(value.replace("Q", ""))
    const question = questions[questionNumber - 1]
    const relatedNeed = question.need
    scores[relatedNeed] += parseFloat(values[`Q${questionNumber}`])
  })
  return Object.entries(scores)
    .sort(([, a], [, b]) => b - a)
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
}

const solveCombination = (primary, secondary, needDescriptions) => {
  return needDescriptions?.kombination.filter(
    (combo) =>
      (combo.framsta_behov.toLowerCase() === primary.toLowerCase() &&
        combo.sekundara_behov.toLowerCase() === secondary.toLowerCase()) ||
      (combo.framsta_behov.toLowerCase() === secondary.toLowerCase() &&
        combo.sekundara_behov.toLowerCase() === primary.toLowerCase())
  )
}
const withPercent = (result) => {
  return Object.keys(result).map((res) => ({
    behov: res,
    poang: result[res],
    procent: Math.round((result[res] / 14) * 100) + "%",
  }))
}

function ResultPage({
  needDescriptions,
  questions,
  values,
  contactId,
  onRestart,
  needTags,
  premiumPaid,
  premiumEmployee,
  resultsNote,
}) {
  const { t } = useTranslation()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams();
  let scores = {
    "Unikitet/Signifikans": 0,
    "Kärlek/Samhörighet": 0,
    Trygghet: 0,
    "Spänning/Variation": 0,
    "Växa/Utveckling": 0,
    "Ge/Bidra": 0,
  }
  let calculateScores = true
  let primary = null
  let secondary = null
  if (resultsNote) {
    resultsNote = resultsNote.replace("6HN test resultat: ", "")
    resultsNote = resultsNote.replaceAll(" - ", ":")
    resultsNote = resultsNote.replace("Fullständigt resultat: \n", "")
    resultsNote = resultsNote.replace(", ", "\n")
    resultsNote = resultsNote.replaceAll(/[\[,\-\]]/g, "")
    const resultsStrings = resultsNote.split("\n")

    resultsStrings.forEach((item) => {
      item = item.trim()
      if (item.indexOf("Primära behov:") >= 0) {
        primary = item.replace("Primära behov:", "").trim()
      } else if (item.indexOf("sekundära behov:") >= 0) {
        secondary = item.replace("sekundära behov:", "").replace(".", "").trim()
      } else if (item.indexOf("Plats") >= 0 && item.indexOf("(") > 0) {
        const need = item.substring(8, item.indexOf(":")).trim()
        scores[need] = parseFloat(
          item.substring(item.indexOf(":") + 1, item.indexOf("("))
        )
      } else if (item.indexOf("Plats") >= 0) {
        const need = item.substring(8, item.indexOf(":", 9)).trim()
        scores[need] = parseFloat(
          item.substring(item.indexOf(":", 9) + 1, item.indexOf("%"))
        )
        calculateScores = false
      }
    })
  }

  const results = resultsNote
    ? Object.entries(scores)
      .sort(([, a], [, b]) => b - a)
      .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
    : calculateSortedScores(questions, values)
  primary = primary ? primary : Object.keys(results)[0]
  secondary = secondary ? secondary : Object.keys(results)[1]
  const combination = solveCombination(primary, secondary, needDescriptions)
  const primaryNeedTagId = needTags.find(
    ({ tag }) => tag.includes(primary) && tag.includes("6HN Primärt behov")
  )
  const secondaryNeedTagId = needTags.find(
    ({ tag }) => tag.includes(secondary) && tag.includes("6HN Sekundärt behov")
  )

  const isEnglishRoute = searchParams.get('lang') === 'en';

  const resultLinks = {
    "Unikitet/Signifikans": isEnglishRoute ? process.env.REACT_APP_NEED_PDF_UNIQUE_EN : process.env.REACT_APP_NEED_PDF_UNIQUE,
    "Kärlek/Samhörighet": isEnglishRoute ? process.env.REACT_APP_NEED_PDF_LOVE_EN : process.env.REACT_APP_NEED_PDF_LOVE,
    Trygghet: isEnglishRoute ? process.env.REACT_APP_NEED_PDF_SECURITY_EN : process.env.REACT_APP_NEED_PDF_SECURITY,
    "Spänning/Variation": isEnglishRoute ? process.env.REACT_APP_NEED_PDF_EXCITEMENT_EN : process.env.REACT_APP_NEED_PDF_EXCITEMENT,
    "Växa/Utveckling": isEnglishRoute ? process.env.REACT_APP_NEED_PDF_GROWTH_EN : process.env.REACT_APP_NEED_PDF_GROWTH,
    "Ge/Bidra": isEnglishRoute ? process.env.REACT_APP_NEED_PDF_GIVE_EN : process.env.REACT_APP_NEED_PDF_GIVE,
  }
  const translateNeed = (need) => {
    const isEnglishRoute = searchParams.get('lang') === 'en';
    if (isEnglishRoute) {
      switch (need) {
        case "Unikitet/Signifikans":
          return "Significance"
        case "Kärlek/Samhörighet":
          return "Love/Connection"
        case "Trygghet":
          return "Certainty"
        case "Spänning/Variation":
          return "Uncertainty/Variety"
        case "Växa/Utveckling":
          return "Growth"
        case "Ge/Bidra":
          return "Contribution"
        default:
          return need
      }
    }
    return need
  }
  useEffect(() => {
    if (resultsNote) return
    history.push(`?result=${contactId}`)
    fetch("/.netlify/functions/submit_result", {
      method: "POST",
      body: JSON.stringify({
        contact: contactId,
        primary: primary,
        primaryTagId: primaryNeedTagId.id,
        secondary: secondary,
        secondaryTagId: secondaryNeedTagId.id,
        scores: withPercent(results),
        resultId: contactId,
      }),
    }).then((response) => {
      response.json().then((data) => {
        console.log(data)
      })
    })
  })
  return (
    <div>
      <h1 className="pt-4 pb-4 text-2xl font-bold hankenBold">
        {t("results.title")}
      </h1>
      <div className="flex flex-col flex-wrap">
        {Object.keys(results).map((result) => (
          <div
            key={`${result}`}
            className="flex items-center justify-start score"
          >
            <div className="mr-2 star">
              <Star />
            </div>
            <div>
              <p className="text-xs md:text-lg">
                <span className="text-sm font-bold md:text-xl">
                  {translateNeed(result)}
                </span>{" "}
                -{" "}
                {calculateScores
                  ? Math.round((results[result] / 14) * 100)
                  : results[result]}{" "}
                %
              </p>
            </div>
          </div>
        ))}
      </div>

      <section className="resultSection">
        <div className="flex items-center justify-center w-full h-6 mt-10 mb-4 sectionStar">
          <Star />
        </div>
        <h2 className="text-2xl text-center hankenBold">
          {t("results.yourNeeds", {
            first: capitalizeFirstLetter(translateNeed(primary)),
            second: capitalizeFirstLetter(translateNeed(secondary)),
          })}
        </h2>
        {combination && <PrismicRichText field={combination[0].text} />}
        {premiumPaid ? (
          <div className="flex items-center justify-center w-full h-6 mt-10 mb-4 sectionStar">
            {t("results.downloadText")}
          </div>
        ) : null}
      </section>
      {premiumPaid ? (
        <section className="flex flex-row justify-between resultSection">
          <div className="flex w-1/3">
            <a
              target="_blank"
              href={resultLinks[primary]}
              className="flex items-center justify-center max-w-xs p-4 mx-auto mt-6 font-normal text-white bg-primaryBlue sm:w-64"
              rel="noreferrer"
            >
              {translateNeed(primary)}
            </a>
          </div>
          <div className="flex w-1/3">
            <a
              href={resultLinks[secondary]}
              target="_blank"
              className="flex items-center justify-center max-w-xs p-4 mx-auto mt-6 font-normal text-white bg-primaryBlue sm:w-64"
              rel="noreferrer"
            >
              {translateNeed(secondary)}
            </a>
          </div>
        </section>
      ) : null}
      {premiumEmployee ? null : (
        <LearnMore primary={primary} premiumPaid={premiumPaid} />
      )}
    </div>
  )
}

export default ResultPage
