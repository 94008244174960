import React from "react"
import { useField } from "formik"
import { Question } from "../typings"
import { useTranslation } from "react-i18next"

interface CustomRadioProps {
  label: string
  id?: string
  name: string
  value: string
  type: string
  className: string
}

function CustomRadio({ label, ...props }: CustomRadioProps) {
  const [field, meta] = useField(props)

  return (
    <div className="relative flex flex-col items-center w-1/3 radio">
      <input {...field} {...props} className={`${props.className}`} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
      <span className="checkmark" {...field}></span>
      <label
        className={`select-none mt-2 font-thin ${props.className}`}
        htmlFor={props.id || props.name}
      >
        {label}
      </label>
    </div>
  )
}

interface QuestionProps {
  question: Question
  pageIndex: number
}

function QuestionComponent({ question, pageIndex }: QuestionProps) {
  const { t } = useTranslation()
  return (
    <div
      role="group"
      aria-labelledby="question-answers"
      className="relative flex flex-col items-center justify-center w-full pb-20 my-4 md:pb-40"
    >
      <h3 className="mb-4 text-lg text-center hankenBold md:text-3xl md:mb-6">
        {question.number}.
      </h3>
      <div className="mb-4 divider md:mb-8"></div>
      <h4 className="w-full mb-6 text-center hankenBold text-md md:text-lg md:mb-16">
        {question.title}
      </h4>
      <div className="relative w-full bg-line">
        <div className="flex w-full radio-container">
          <CustomRadio
            id={`Q${question.number}A`}
            label={t("questions.false")}
            name={`Q${question.number}`}
            value={"0"}
            type="radio"
            className="text-left customRadio"
          />
          <CustomRadio
            id={`Q${question.number}B`}
            label={t("questions.partially")}
            name={`Q${question.number}`}
            value={"0.5"}
            type="radio"
            className="text-center customRadio"
          />
          <CustomRadio
            id={`Q${question.number}C`}
            label={t("questions.true")}
            name={`Q${question.number}`}
            value={"1"}
            type="radio"
            className="text-right customRadio"
          />
        </div>
      </div>
    </div>
  )
}

export default QuestionComponent
