import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import en from "./locales/en/index.json"
import sv from "./locales/sv/index.json"

const resources = {
  en: {
    translation: en,
  },
  sv: {
    translation: sv,
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  supportedLngs: ["en", "sv"],

  interpolation: {
    escapeValue: false,
  },
})

export default i18n
