import React from "react"
import { Question } from "./typings"
import { BrowserRouter, useLocation, useSearchParams } from "react-router-dom"
import Form from "./components/Form"
import smoothscroll from "smoothscroll-polyfill"
import Helmet from "react-helmet"
import * as prismic from "@prismicio/client"
import { useTranslation } from "react-i18next"
import "./index.css"

smoothscroll.polyfill()

const apiEndpoint = "https://6humanneeds.prismic.io/api/v2"
const Client = prismic.createClient(apiEndpoint)

function MainPage() {
  const [questions, setQuestions] = React.useState<Question[] | null>([])
  const [results, setResults] = React.useState<any>()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const { i18n } = useTranslation()

  const isEnglishRoute = searchParams.get('lang') === 'en' //location.pathname.includes("/en/")

  React.useEffect(() => {
    if (isEnglishRoute) {
      i18n.changeLanguage("en")
    } else {
      i18n.changeLanguage("sv")
    }
  }, [i18n, isEnglishRoute, location.pathname])

  React.useEffect(() => {
    const fetchData = async () => {
      const fragor = Client.get({
        lang: isEnglishRoute ? "en-US" : "sv-SE",
        predicates: prismic.filter.at("document.type", "fragor"),
      })
      const behov = Client.get({
        lang: isEnglishRoute ? "en-US" : "sv-SE",
        filters: prismic.filter.at("document.type", "behov"),
      })

      if ((await fragor)?.results[0]?.data) {
        let ID = 1
          ; (await fragor).results[0].data.fragor.forEach(
            (question: { fraga: any; relaterat_behov: any }) => {
              const title = question.fraga[0].text
              const relatedNeed = question.relaterat_behov
              const questionID = ID
              setQuestions((questions) => [
                ...questions,
                { title: title, need: relatedNeed, number: questionID },
              ])
              ID++
            }
          )
      } else {
        throw new Error("Kunde inte hämta frågor från Prismic CMS")
      }

      if ((await behov)?.results[0].data) {
        setResults((await behov).results[0].data)
      } else {
        throw new Error("Kunde inte hämta behovstexterna från Prismic CMS")
      }
    }
    try {
      fetchData()
    } catch (error) {
      console.error(error)
    }
  }, [])

  return <Form questions={questions} needDescriptions={results} />
}

function App() {
  return (
    <div className="w-full h-full theform">
      <Helmet>
        <title>Vilka är dina mänskliga behov? Gör testet här!”</title>
        <meta
          name="description"
          content="Få en guide till vilka behov du har och verktygen du behöver för att nyttja dem på bästa sätt"
        />
        <meta
          property="og:title"
          content="Vilka är dina mänskliga behov? Gör testet gratis här!”"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Få en guide till vilka behov du har och verktygen du behöver för att nyttja dem på bästa sätt"
        />
        <meta property="og:image" content="/OG.png" />
        <meta property="og:url" content="https://6humanneeds.netlify.app/" />
      </Helmet>

      <BrowserRouter>
        <MainPage />
      </BrowserRouter>
    </div>
  )
}

export default App
