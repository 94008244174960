import React from "react"
import * as serviceWorker from "./serviceWorker"

import "./i18n.js"
import "./index.css"
import "./components/styles.css"
import AppWrapper from "./components/AppWrapper"
import { createRoot } from "react-dom/client"

const container = document.getElementById("root")
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(<AppWrapper />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
