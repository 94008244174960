import React from "react"
import { useTranslation } from "react-i18next"

const getLink = (premium, primary) => {
  if (premium)
    return "https://calendly.com/magnus-helgesson/6mb-strategisamtal-personlig-utveckling"
  switch (primary) {
    case "Unikitet/Signifikans":
      return "https://www.magnushelgesson.com/6mb-unikitet/"
    case "Spänning/Variation":
      return "https://www.magnushelgesson.com/6mb-spanning/"
    case "Trygghet":
      return "https://www.magnushelgesson.com/6mb-trygghet/"
    case "Kärlek/Samhörighet":
      return "https://www.magnushelgesson.com/6mb-karlek/"
    case "Växa/Utveckling":
      return "https://www.magnushelgesson.com/6mb-vaxa/"
    case "Ge/Bidra":
      return "https://www.magnushelgesson.com/6mb-ge/"
    default:
      return "https://www.magnushelgesson.com/6mb-trygghet/"
  }
}

const LearnMore = ({ primary, premiumPaid }) => {
  let link = getLink(premiumPaid, primary)
  const { t } = useTranslation()
  return (
    <div className="p-12 mt-10 font-bold text-center bg-white rounded-md md:p-16">
      {t("results.takeNextSteps")}
      <a
        href={link}
        target="_blank"
        className="flex items-center justify-center w-48 max-w-xs p-4 mx-auto mt-6 font-normal text-white bg-primaryBlue sm:w-64"
        rel="noreferrer"
      >
        {premiumPaid ? t("results.bookCall") : t("results.clickHere")}
      </a>
    </div>
  )
}

export default LearnMore
