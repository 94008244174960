import React, { createRef, useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import { FieldArray, Form as FormikForm, Formik } from "formik"

import { Question, WithChildren } from "../typings"
import QuestionComponent from "./Question"
import Header from "./Header"
import Marker from "./SectionMarker"
import AutoScroller from "./AutoScroller"
import StartCard from "./StartCard"
import ResultPage from "./ResultPage"
import { useTranslation } from "react-i18next"

type FormProps = WithChildren<{
  questions: Question[] | null
  needDescriptions: any
}>

const questionsPerPage = 10
const redirectForPaymentUrl = process.env.REACT_APP_PAYMENT_REDIRECT_URL
const redirectForSecondPaymentUrl =
  process.env.REACT_APP_SECOND_PAYMENT_REDIRECT_URL

const getSectionEntries = (section: number) => {
  let questionIndex = []
  for (
    let index = section * questionsPerPage + 1;
    index <= section * questionsPerPage + questionsPerPage;
    index++
  ) {
    questionIndex.push(index)
  }
  return questionIndex
}

const isSectionFilled = (indexes: number[], values: object, numQuestions) => {
  let empties = []
  withQ(indexes).forEach((index) => {
    if (values[index]) {
      empties.push(index)
    }
  })
  return empties.length >= numQuestions
}

const withQ = (indexes: number[]) => {
  return indexes.map((index) => "Q" + index)
}

const renderEmpty = (length: number) => {
  let sections = []
  for (let i = 0; i < length; i++) {
    sections.push(i)
  }
  return sections
}

const scrollToNextQuestion = (questionIndex, refs) => {
  const element = refs[questionIndex]
  if (element && element.current) {
    const scrollY = element.current.offsetTop
    window.scrollTo({ left: 0, top: scrollY - 200, behavior: "smooth" })
  } else {
    //fallback scroll fix for last unfilled page
    const lg = window.innerWidth > 768
    window.scrollTo({
      left: 0,
      top: window.scrollY + (lg ? 423 : 290),
      behavior: "smooth",
    })
  }
}

const getUnfilled = (questions: Question[], values: any) => {
  return questions
    .map((q) => "Q" + q.number)
    .filter((questionNumber) => !(questionNumber in values))
}

function Form({ questions, needDescriptions }: FormProps) {
  const [currentPage, setCurrentPage] = useState(0)
  const [unfilledPage, setUnfilledPage] = useState(false)
  const [savedUnfilled, setSavedUnfilled] = useState<string[]>([])
  const [appState, setAppState] = useState<string>("Start")
  const [resultsNote, setResultsNote] = useState<string>(null)
  const query = new URLSearchParams(useLocation().search)
  const [state, setState] = useState({
    name: query.get("email"),
    email: query.get("email"),
    resultId: query.get("result"),
  })
  const [errorDisplay, setErrorDisplay] = useState(false)
  const [contactId, setContactId] = useState(null)
  const [tagId, setTagId] = useState(null)
  const [needTags, setNeedTags] = useState(null)
  const [premiumPaid, setPremiumPaid] = useState(false)
  const [premiumEmployee, setPremiumEmployee] = useState(false)
  const { t } = useTranslation()

  const numSections = Object.keys(questions).length / questionsPerPage

  const elementRef = useRef([])
  elementRef.current = Array(Object.keys(questions).length)
    .fill("")
    .map((_, i) => elementRef.current[i] || createRef())

  const isOnLastPage = currentPage === Math.floor(numSections)

  const onInputChange = (e) => {
    const value = e.target.value
    setState({
      ...state,
      [e.target.name]: value,
    })
    setErrorDisplay(false)
  }

  const checkForPayment = () =>
    new Promise<any>((resolve, reject) => {
      return fetch("/.netlify/functions/getTagsForEmail", {
        method: "POST",
        body: JSON.stringify({
          email: state.email,
        }),
      }).then((response) => {
        if (!response.ok) {
          return reject("No tags available")
        } else {
          response.json().then((data) => {
            resolve(data)
          })
        }
      })
    })

  const checkResultsNote = (contact) =>
    new Promise<any>((resolve, reject) => {
      return fetch("/.netlify/functions/get_note", {
        method: "POST",
        body: JSON.stringify({
          contactId: contact,
        }),
      }).then((response) => {
        if (!response.ok) {
          return reject("No tags available")
        } else {
          response.json().then((data) => {
            resolve(data)
          })
        }
      })
    })

  const initTest = (contact) =>
    new Promise<any>((resolve, reject) => {
      fetch("/.netlify/functions/contact_init", {
        method: "POST",
        body: JSON.stringify({
          contact,
        }),
      }).then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            resolve(data)
          })
        } else {
          return reject("Init test failed")
        }
      })
    })

  const getResultsTag = (tagName) =>
    new Promise<any>((resolve, reject) => {
      return fetch("/.netlify/functions/get_result", {
        method: "POST",
        body: JSON.stringify({
          resultId: tagName,
        }),
      }).then((response) => {
        if (!response.ok) {
          return reject("No tags available")
        } else {
          response.json().then((data) => {
            resolve(data)
          })
        }
      })
    })

  useEffect(() => {
    try {
      fetch("/.netlify/functions/getTags").then((response) => {
        response.json().then((data) => {
          const mappedTags = data.map((needTag) => ({
            tag: needTag.tag,
            id: needTag.id,
          }))
          setNeedTags(mappedTags)

          const testFinishedTags = mappedTags?.filter(({ tag }) =>
            ["Gjort test 6HN", "gjorttest"].includes(tag)
          )
          const basicTags = mappedTags?.filter(({ tag }) =>
            ["6MB Basic betald 2", "6MB Basic betald"].includes(tag)
          )
          const premiumTags = mappedTags?.filter(({ tag }) =>
            [
              "6MB premium betald",
              "6MB IF SHAC",
              "6MB premium betald 2",
              "6MB Premium IF SHAC",
              "6MB Premium Anställd",
            ].includes(tag)
          )
          const isPremiumEmployee = mappedTags?.filter(({ tag }) =>
            ["6MB Premium Anställd"].includes(tag)
          )

          if (state.resultId) {
            getResultsTag(state.resultId)
              .then((data) => {
                setResultsNote(data.note)
                const userTags = data.tags
                const foundPremiumTag = userTags.find(({ tag }) =>
                  premiumTags.some(({ id }) => id === tag)
                )
                const foundEmployeeTag = userTags.find(({ tag }) =>
                  isPremiumEmployee.some(({ id }) => id === tag)
                )
                if (foundPremiumTag) setPremiumPaid(true)
                setPremiumEmployee(foundEmployeeTag)

                setAppState("ResultScreen")
              })
              .catch((e) => console.error(e))
          } else if (state.email) {
            checkForPayment()
              .then((data) => {
                const foundTestFinishedTag = data.find(({ tag }) =>
                  testFinishedTags.some(({ id }) => id === tag)
                )
                const foundBasicTag = data.find(({ tag }) =>
                  basicTags.some(({ id }) => id === tag)
                )
                const foundPremiumTag = data.find(({ tag }) =>
                  premiumTags.some(({ id }) => id === tag)
                )
                const foundEmployeeTag = data.find(({ tag }) =>
                  isPremiumEmployee.some(({ id }) => id === tag)
                )

                if (foundTestFinishedTag && foundBasicTag && !foundPremiumTag) {
                  window.location.href = redirectForSecondPaymentUrl
                } else if (foundPremiumTag && !foundTestFinishedTag) {
                  setAppState("Form")
                  setPremiumPaid(true)
                  setPremiumEmployee(foundEmployeeTag)
                  setContactId(foundPremiumTag.contact)
                  initTest(foundPremiumTag.contact)
                    .then((data) => setTagId(data.contactTag.id))
                    .catch((e) => console.error(e))
                } else if (foundBasicTag && !foundTestFinishedTag) {
                  setAppState("Form")
                  setContactId(foundBasicTag.contact)
                  initTest(foundBasicTag.contact)
                    .then((data) => setTagId(data.contactTag.id))
                    .catch((e) => console.error(e))
                } else {
                  window.location.href = redirectForPaymentUrl
                }
              })
              .catch(() => (window.location.href = redirectForPaymentUrl))
          } else {
            window.location.href = redirectForPaymentUrl
          }
        })
      })
    } catch (e) {
      console.error(e)
    }
  }, [])

  return (
    <Formik
      initialValues={{}}
      onSubmit={(values) => {
        const unfilled = getUnfilled(questions, values)
        window.scrollTo({ left: 0, top: 0, behavior: "smooth" })
        if (unfilled.length > 0) {
          setUnfilledPage(true)
          setSavedUnfilled(unfilled)
        } else {
          fetch("/.netlify/functions/submit_test", {
            method: "POST",
            body: JSON.stringify({
              tag: tagId,
              contact: contactId,
            }),
          }).then((response) => {
            response.json().then((data) => {
              setUnfilledPage(false)
              setAppState("ResultScreen")
            })
          })
        }
      }}
    >
      {({ values, resetForm }) =>
        appState === "Form" || appState === "ResultScreen" ? (
          <>
            <AutoScroller
              values={values}
              onNewAction={(t) => {
                const questionAnswered =
                  parseInt(Object.keys(t)[0].replace("Q", "")) %
                  questionsPerPage
                scrollToNextQuestion(
                  questionAnswered > 0
                    ? questionAnswered
                    : questionsPerPage - 1,
                  elementRef.current
                )
              }}
            />

            <FormikForm className="w-full max-w-3xl px-8 pt-24 mx-auto md:pt-40">
              <Header title={currentPage.toString()}>
                {resultsNote
                  ? null
                  : renderEmpty(numSections).map((_, index) => (
                      <Marker
                        jumpTo={(sectionIndex) => {
                          setUnfilledPage(false)
                          setAppState("Form")
                          setCurrentPage(sectionIndex)
                          window.scrollTo({
                            left: 0,
                            top: 0,
                            behavior: "smooth",
                          })
                        }}
                        key={`marker${index}`}
                        active={!unfilledPage ? index === currentPage : false}
                        section={{
                          isFilled: isSectionFilled(
                            getSectionEntries(index),
                            values,
                            index === Math.floor(numSections)
                              ? Object.keys(questions).length % questionsPerPage
                              : questionsPerPage
                          ),
                          sectionIndex: index,
                        }}
                      />
                    ))}
              </Header>

              {unfilledPage && appState === "Form" && (
                <h4 className="w-full max-w-md mx-auto my-16 text-lg text-center hankenBold">
                  {t("questions.missingQuestions")}
                </h4>
              )}

              {appState === "ResultScreen" ? (
                <ResultPage
                  needTags={needTags}
                  contactId={contactId}
                  questions={questions}
                  values={values}
                  needDescriptions={needDescriptions}
                  premiumPaid={premiumPaid}
                  premiumEmployee={premiumEmployee}
                  resultsNote={resultsNote}
                  onRestart={() => {
                    // TODO: redirect to the corresponding page
                    resetForm()
                    setAppState("Init")
                    setCurrentPage(0)
                    window.scrollTo({ left: 0, top: 0, behavior: "smooth" })
                  }}
                />
              ) : (
                <>
                  <FieldArray
                    name="questions"
                    render={() =>
                      !unfilledPage
                        ? questions
                            .filter(
                              (q, index) =>
                                index <
                                  currentPage * questionsPerPage +
                                    questionsPerPage &&
                                index >= currentPage * questionsPerPage
                            )
                            .map((question, pageIndex) => (
                              <div
                                key={`Q:${question.number}`}
                                ref={elementRef.current[pageIndex]}
                              >
                                <QuestionComponent
                                  pageIndex={pageIndex}
                                  question={question}
                                />
                              </div>
                            ))
                        : savedUnfilled.map((question) =>
                            questions
                              .filter(
                                (q) =>
                                  q.number.toString() ===
                                  question.replace("Q", "")
                              )
                              .map((q, pageIndex) => (
                                <div
                                  key={`Q:${q.number}`}
                                  ref={elementRef.current[pageIndex]}
                                >
                                  <QuestionComponent
                                    pageIndex={pageIndex}
                                    question={q}
                                  />
                                </div>
                              ))
                          )
                    }
                  />
                  {isOnLastPage ? (
                    <button
                      type="submit"
                      className="flex items-center justify-center w-full max-w-xs p-4 mx-auto mb-20 text-white bg-primaryBlue"
                    >
                      {t("questions.send")}
                    </button>
                  ) : (
                    <h3 className="w-full max-w-md mx-auto mb-6 font-normal text-center text-md">
                      {t("questions.goBackText")}
                    </h3>
                  )}
                </>
              )}
            </FormikForm>

            {!resultsNote ? (
              <div className="flex flex-row items-center justify-between w-full h-20 mt-10 md:mt-20 navigation">
                {currentPage !== 0 ? (
                  <button
                    className="w-1/2 border-2 border-primaryBlue navigation-button"
                    onClick={(e) => {
                      e.preventDefault()
                      setUnfilledPage(false)
                      if (appState === "ResultScreen") {
                        setCurrentPage((page) => Math.max(0, page))
                        setAppState("Form")
                      } else {
                        setCurrentPage((page) => Math.max(0, page - 1))
                      }

                      window.scrollTo({ left: 0, top: 0, behavior: "smooth" })
                    }}
                  >
                    <svg
                      style={{ width: "24px", marginRight: "10px" }}
                      width="10"
                      height="16"
                      viewBox="0 0 10 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.75464 15L1.75464 8L8.75464 1"
                        stroke="#091026"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span className="leading-5 text-left hankenBold text-md">
                      {t("questions.previous")}
                    </span>
                  </button>
                ) : (
                  <div />
                )}

                {!isOnLastPage ? (
                  <button
                    className="w-1/2 text-white bg-primaryBlue navigation-button"
                    onClick={(e) => {
                      e.preventDefault()
                      setCurrentPage((page) =>
                        Math.round(
                          Math.min(
                            questions.length / questionsPerPage,
                            page + 1
                          )
                        )
                      )
                      window.scrollTo({ left: 0, top: 0, behavior: "smooth" })
                    }}
                  >
                    <span className="mr-3 leading-5 text-left hankenBold text-md">
                      {t("questions.next")}
                    </span>
                    <svg
                      style={{ transform: "rotate(180deg)", width: "24px" }}
                      width="10"
                      height="16"
                      viewBox="0 0 10 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.75464 15L1.75464 8L8.75464 1"
                        stroke="#FAFAFA"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                ) : (
                  <div />
                )}
              </div>
            ) : null}
          </>
        ) : null
      }
    </Formik>
  )
}

export default Form
