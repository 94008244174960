import React, {Component} from "react"
import App from "../App";

class AppWrapper extends Component {
    componentDidMount() {
        window.top.postMessage({
            'id': 'root-height',
            'height': document.getElementById('root').offsetHeight,
        }, '*');
    }

    render() {
        return <App/>
    }
}

export default AppWrapper