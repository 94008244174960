import React from "react"
import "./styles.css"
import { WithChildren } from "../typings"

import Logo from "../assets/LOGO2x.png"

type HeaderProps = WithChildren<{
  title: string
}>

function Header({ title, children }: HeaderProps) {
  return (
    <header className="fixed w-full h-12 header z-50 top-0 left-0">
      <img className="logo" src={Logo} alt="logotype" />
      {children}
    </header>
  )
}

export default Header
