import React, { useEffect, useState } from "react"
import "./styles.css"

interface ScrolledProps {
  values: any
  onNewAction: any
}

function AutoScroller({ values, onNewAction }: ScrolledProps) {
  const [_, setTouchedSaved] = useState(values)
  useEffect(() => {
    setTouchedSaved(values)

    //only scroll if we are setting a new value
    if (Object.keys(values).length !== Object.keys(_).length) {
      const diff = Object.keys(values).reduce((diff, key) => {
        if (_[key] === values[key]) return diff
        return {
          ...diff,
          [key]: values[key],
        }
      }, {})
      onNewAction(diff)
    }
  }, [values])

  return <></>
}

export default AutoScroller
